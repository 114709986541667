.telegram_component {
  position: relative;
  margin-top: calc(var(--index) * 1);
  min-height: 160px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.telegram_component section {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 250px 1fr 300px;
  gap: 10px;
  overflow: hidden;
}
.post_content {
  padding: calc(var(--index) * 0.1);
  background-color: transparent;
  border-radius: 10px;
}
.photo_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}
.photo_container img {
  max-width: 220px;
  margin: 0 auto;
}

.photo_container div {
  margin-top: auto;
  padding: 10px 20px;
  text-align: center;
  border: 1px solid #808080;
  border-radius: 10px;
}
.post_options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.option {
  padding: 10px;
  border-bottom: 1px solid #808080;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  transition: 0.7s;
}
.option span {
  transition: 0.7s;
}
.add_telegram_button {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px dashed #808080;
  border-radius: 10px;
  cursor: pointer;
}
.added_buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.added_button {
  position: relative;
  text-align: center;
  padding: 10px 20px;
  background-color: #2e3031;
  border-radius: 5px 5px 15px 15px;
}
.added_button input {
  width: max-content;
  text-align: center;
}
.sender_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.send_button {
  border: none;
  color: black;
  margin: 30px 0 0 0;
  padding: 10px 20px;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.added_button span:nth-child(1) {
  position: absolute;
  top: 0;
  right: 5px;
}
.added_button span:nth-child(2) {
  position: absolute;
  top: -20px;
  right: 0;
  cursor: pointer;
}
.loading {
  width: 25px;
  height: 25px;
  border: 3px solid #dbdbdb;
  border-top: 3px solid #363535;
  border-radius: 50%;
  margin: 0 auto;
  animation: 1s loading linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .telegram_component section {
    grid-template-columns: 200px 1fr 220px;
  }
  .photo_container img {
    max-width: 200px;
  }
}
@media (max-width: 750px) {
  .telegram_component section {
    grid-template-columns: 250px 1fr;
  }
}
@media (max-width: 550px) {
  .telegram_component section {
    grid-template-columns: 1fr;
  }
  .head {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .post_content {
    min-height: 150px;
  }
}
