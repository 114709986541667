.mainOnScale {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}
.head {
  margin: 0 auto 20px auto;
  text-align: center;
  font-size: calc(var(--index) * 3);
  font-weight: 900;
}
.head_h3 {
  font-size: calc(var(--index) * 3);
  text-align: center;
  margin-bottom: 20px;
}

.stars {
  display: grid;
  gap: calc(var(--index));
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 850px) {
  .head {
    font-size: calc(var(--index) * 2.5);
  }
  .stars {
    grid-template-columns: repeat(3, 1fr);
  }
  .head_h3 {
    font-size: calc(var(--index) * 2.5);
  }
}
@media (max-width: 520px) {
  .head_h3 {
    margin: 0 auto 20px auto;
  }
}
@media (max-width: 500px) {
  .mainOnScale{
    width: 95vw;
  }
}
