.head_vizual_result {
  width: 100%;
  display: flex;
  justify-content: center;
}
.headCase {
  margin: 0 auto;
  font-size: 26px;
  letter-spacing: 1px;
  font-weight: 100;
  margin-bottom: 15px;
  font-family: "GrandisExtended-light";
}

.vizualContainer {
  min-height: 50vh;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  width: max-content;
  max-width: 98vw;
  position: relative;
  max-height: 98vh;
  overflow-y: auto;
}
.vizualContainer::-webkit-scrollbar {
  display: none;
}
.vizualContainerMoreThree {
  padding: 20px 30px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5vw;
  grid-row-gap: 2vh;
  width: max-content;
}
.variants_and_result_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.current_results {
  color: black;
  background: #40404085;
  height: max-content;
  text-align: center;
  transition: 1s;
  border-radius: 16px;
  width: 30vw;
  overflow: hidden;
  font-size: 44px;
  position: relative;
  margin: 3px 0;
}
.current_results div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.current_results span {
  position: relative;
  z-index: 1;
}
.resultVariant {
  max-width: 60vw;
}
.main_questions_to_lector {
  min-width: 90vw;
  max-width: 98vw;
  min-height: 90vh;
  max-height: 90vh;
  padding: calc(var(--index));
  border-radius: 20px;
}
.main_questions_to_lector_title {
  font-family: "GrandisExtended-light";
  letter-spacing: 3px;
  font-weight: 900;
  font-size: 48px;
  margin-bottom: 40px;
}
.main_questions_to_lector_title span {
  font-family: "GrandisExtended-light";
  letter-spacing: 3px;
  font-weight: 900;
  font-size: 58px;
}
.scroll_container {
  overflow-y: scroll;
  height: 85%;
}
.scroll_container::-webkit-scrollbar {
  display: none;
}
.current_approve_question {
  display: flex;
  justify-content: space-between;
  gap: 5vw;
  align-items: baseline;
  padding: 15px 25px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
}
.current_approve_question div:nth-child(1) {
  font-size: 42px;
}
.current_approve_question div:nth-child(2) {
  font-size: 32px;
}
.range {
  opacity: 0.6;
  display: flex;
  align-items: center;
  gap: 10px;
}
.range span {
  color: white;
}
.for_stars {
  height: max-content;
  width: max-content;
  padding: 0;
  position: relative;
}
.line_stars {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  overflow: hidden;
  transition: 1s;
}
.question_quiz {
  margin: 45px 0 0 0;
  font-family: "GrandisExtended-light";
  border: 2px dashed rgba(255, 255, 255, 0.3);
  padding: 10px 20px;
  text-align: center;
  font-size: 28px;
  border-radius: 10em;
  position: relative;
}
.question_quiz div {
  font-family: "GrandisExtended-light";
}
.question_quiz img {
  width: 150px;
  height: 70px;
  position: absolute;
  bottom: 80%;
}
.one_person_result {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: 330px 1fr 370px;
  gap: calc(var(--index) * 2);
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 5px auto;
}
.person_name {
  font-size: 28px;
  position: relative;
}
.is_open {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: max-content;
}
.clouse_open_all_variants {
  cursor: pointer;
}
.correct_uncorrect_counts {
  display: flex;
  justify-content: space-between;
  width: 350px;
  padding: 0 0 5px 0;
}
.correct_uncorrect_counts span {
  font-size: 28px;
}
