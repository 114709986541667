.container {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: calc(var(--index) * 1) calc(var(--index) * 0.5);
  min-width: 40vw;
  max-width: fit-content;
  background-color: rgba(64, 64, 64, 0.5);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 0.5s;
  /* z-index: 10; */
}
.activity_bulb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 15px;
}
.exit_button {
  position: absolute;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: calc(var(--index) * 0.7);
  font-weight: 900;
  top: calc(var(--index) * 1.2);
  right: 24px;
  cursor: pointer;
  z-index: 2;
}
.exit_button img {
  max-width: calc(var(--index) * 1);
}
.step_number_two {
  position: absolute;
  right: 24px;
  bottom: 40px;
  text-align: end;
  z-index: 2;
}
.step_number_two h2 {
  background-color: white;
  border-radius: 10em;
  color: black;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 1);
  cursor: pointer;
  font-size: calc(var(--index) * 0.5);
  font-weight: lighter;
  width: max-content;
  margin: 0 0 calc(var(--index) * 0.1) auto;
}
.step_number_two div {
  width: calc(var(--index));
  height: calc(var(--index));
  margin-right: 5px;
}
.card_image {
  width: calc(var(--index) * 25);
  height: calc(var(--index) * 15);
  border-bottom-left-radius: 24px;
  border-top-left-radius: 24px;
}
.step_number_two span {
  margin-right: 10px;
  color: black;
  font-size: calc(var(--index) * 0.5);
}
.step_number_two p {
  color: #808080;
  font-size: calc(var(--index) * 0.5);
}
.logoContainer {
  margin: 0 auto auto 0;
  width: 100%;
}
.logoContainer img {
  max-width: calc(var(--index) * 30);
}
.loginAndSingContainer {
  width: 100%;
  margin: 0 0 auto 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.loginOrSingUp {
  display: flex;
  gap: 8px;
  padding: 6px;
  border-radius: 10em;
  border: 1px solid white;
  width: max-content;
  transition: 1s;
}
.loginOrSingUp span {
  cursor: pointer;
  padding: 4px 16px;
  border-radius: 5px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.loginAndSingContainer input {
  padding: 11px;
  font-size: 20px;
  transition: 1s;
}
.loginAndSingContainer input::placeholder {
  font-weight: 700;
}
.inputDiv {
  position: relative;
}
.inputDiv input {
  width: 100%;
}
.inputDiv img {
  position: absolute;
  right: 15px;
  bottom: 11px;
  cursor: pointer;
}
.loginAndSingContainer button {
  color: black;
  width: max-content;
  padding: 11px 24px;
  background-color: white;
  border: none;
  border-radius: 10em;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;
}
.colorsSection {
  margin-top: calc(var(--index) * 3);
  display: flex;
  gap: calc(var(--index) * 20);
}

.chooseImage {
  width: calc(var(--index) * 40);
  height: calc(var(--index) * 20);
}

.before_upload_image {
  width: 100%;
  height: 100%;
  background-color: #40404080;
  display: flex;
  justify-content: center;
  align-items: center;
}
.before_upload_image img {
  max-width: 100px;
}
.label {
  display: flex;
  justify-content: space-between;
  padding: 30px 10px 10px 10px;
  border-bottom: 1px solid #808080;
  position: relative;
}
.label span {
  color: #808080;
  font-size: 20px;
  font-weight: 700;
}
.label img {
  max-width: 20px;
  cursor: pointer;
}
.reset_img,
.reset_img_2 {
  position: absolute;
  left: 93%;
  bottom: 100%;
  max-width: none;
  max-width: 25px;
  cursor: pointer;
}
.chooseImage img {
  width: 100%;
}
.uploadImage {
  display: none;
}
.all_colors {
  position: relative;
}

.all_colors h2 {
  font-size: calc(var(--index) * 2);
  color: white;
  font-weight: 900;
  margin-top: -5px;
}
.chooseColors {
  margin-top: 19px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.chooseColors h3,
.chooseColors h4 {
  font-size: calc(var(--index) * 0.2);
  line-height: calc(var(--index) * 1);
}
.chooseColors h4 {
  color: #808080;
}
.chooseColors input[type="color"] {
  border: none;
  width: calc(var(--index) * 4);
  height: calc(var(--index) * 4);
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  outline: none;
  cursor: inherit;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* firefox */
input[type="color"]::-moz-focus-inner {
  border: none;
  padding: 0;
  border-radius: 50%;
}

input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 50%;
  height: 140px;
}
.casesContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding-right: 10px;
  overflow-y: auto;
}
.loading {
  width: 50px;
  min-height: 50px;
  border: 3px solid #ffffff;
  border-top: 3px solid #808080;
  border-radius: 50%;
  margin: 0 auto;
  animation: 1s loading linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.casesContainer::-webkit-scrollbar {
  width: 5px;
}
.casesContainer::-webkit-scrollbar-track {
  background-color: #808080;
  border-radius: 10em;
}
.casesContainer::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 10em;
}
.main_title_of_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.search_sections {
  margin: 0 auto 1vh 0;
  display: flex;
  width: 100%;
  position: relative;
}
.search_sections h3 {
  position: absolute;
  top: -25px;
  left: 0;
}
.search_sections input {
  border-bottom: 1px solid #808080;
  width: 100%;
  padding: 5px;
  font-size: calc(var(--index));
}
.not_found {
  width: calc(var(--index) * 40);
  margin: 15% auto;
  display: block;
}
.h3_not_found {
  text-align: center;
  color: #808080;
  font-size: calc(var(--index));
  margin-top: -50px;
}
.session_title {
  font-family: "GrandisExtended-light";
  margin: 22px auto auto 0;
  font-size: calc(var(--index) * 3);
  letter-spacing: 1px;
  font-weight: bolder;
}
.users {
  font-size: calc(var(--index) * 1.5);
  padding: 9px calc(var(--index) * 1);
  border-radius: 10em;
  background-color: transparent;
  border: 1px dashed white;
  cursor: pointer;
}
.without_sesions_information {
  text-align: center;
  line-height: 140%;
  font-size: calc(var(--index) * 2);
  font-weight: 900;
  color: rgba(255, 255, 255, 0.5);
}
.create_new_session {
  color: black;
  padding: calc(var(--index) * 0.01) calc(var(--index) * 1);
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  background-color: white;
  border-radius: 10em;
  margin: 0 auto;
  font-size: calc(var(--index) * 0.1);
  cursor: pointer;
}
.type_input {
  color: #808080;
}
.create_new_session img {
  max-width: calc(var(--index) * 0.1);
}
.adminPanelSection {
  margin: 0 0 auto 0;
  text-align: center;
  width: 100%;
}
.currentCase {
  padding: calc(var(--index) * 1);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.currentCase h2 {
  font-size: calc(var(--index) * 0.7);
  letter-spacing: 1px;
  font-weight: bolder;
}
.currentCase h6 {
  font-size: calc(var(--index) * 0.2);
  color: #808080;
  font-weight: 700;
}
.currentCase span {
  font-size: calc(var(--index) * 0.5);
  color: #808080;
  font-weight: 700;
}
.edit_image {
  max-width: calc(var(--index) * 3);
}
.editSessionsButton {
  margin-left: auto;
  padding: 24px;
  cursor: pointer;
}
.deleteCase,
.caseStatus,
.activeSession {
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: transparent;
}
.caseStatus {
  background-color: transparent;
  cursor: auto;
}
.activeSession {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
}
.pagination {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 50%;
}
.deleteModalCss {
  position: absolute;
  top: 20vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 3vh 5vw;
  width: max-content;
  border-radius: 1vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 3;
}
.currentCaseElement {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 24px;
  position: relative;
}

.deleteModalCss p {
  font-size: 18px;
}
.deleteModalCss span {
  margin: 0 15px;
  font-size: 20px;
  padding: 5px 15px;
  cursor: pointer;
}
.deleteModalCss span:nth-child(1) {
  background-color: green;
}
.deleteModalCss span:nth-child(2) {
  background-color: red;
}
.sessionComponentContainer {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 5vh 3vw;
  border-radius: 1vw;
  display: flex;
  gap: 2vw;
  position: relative;
}
.statusAndId {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  gap: 1vh;
}
.currentStatus {
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 18px;
}
.blockQuestionsContainer {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(78, 78, 78);
}
.links {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}
.descriptionRextArea {
  margin-top: 20px;
  width: 100%;
  background-color: transparent;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 18px;
}
.descriptionRextArea::placeholder {
  font-size: 18px;
}
.foneImg {
  width: 30vw;
}
.comeback {
  cursor: pointer;
  width: 90px;
  font-size: 20px;
}
.sessionPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.sessionPagination button {
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
}

.previousImagesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  max-height: 190px;
  overflow-y: scroll;
}
.previousImagesContainer::-webkit-scrollbar {
  width: 0;
}

.prevImage {
  cursor: pointer;
  transition: 0.7s;
}
.prevImage:hover {
  transform: scale(1.2);
}
.pvewiew_container {
  position: absolute;
  width: 75%;
  min-height: 70%;
  border-radius: 10px;
  padding: 10px;
}
.pvewiew_container h2 {
  font-size: 16px;
  text-align: center;
  /* width: max-content; */
  margin: 0 auto;
}
.pvewiew_container h3 {
  font-size: 11px;
  /* font-weight: 100; */
  width: max-content;
  margin: 3% auto 0 auto;
}
.pvewiew_container div {
  width: 50px;
  height: 50px;
  margin: 7% auto 0 auto;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* font-weight: 100; */
  cursor: pointer;
}

@media (max-width: 1000px) {
  .prevImage:hover {
    transform: scale(1);
  }
  .sessionComponentContainer {
    flex-direction: column;
  }
  .container {
    width: max-content;
  }
  .foneImg {
    width: 60vw;
  }
  .comeback {
    width: 100%;
    font-size: 25px;
    margin-bottom: 30px;
  }
}
@media (max-width: 900px) {
  .chooseImage {
    width: auto;
    height: auto;
  }
  .colorsSection {
    flex-direction: column;
    gap: calc(var(--index) * 5);
  }
}
@media (max-width: 750px) {
  .sessionPagination {
    margin-bottom: 25px;
  }
  .create_new_session {
    font-size: calc(var(--index) * 1);
  }
  .create_new_session img {
    max-width: calc(var(--index) * 1);
  }
  .card_image {
    width: calc(var(--index) * 16);
    height: calc(var(--index) * 15);
  }
  .activeSession {
    order: 1;
    text-align: center;
  }
  .deleteCase {
    width: max-content;
    margin-left: auto;
    order: -1;
    background-color: transparent;
    padding: 0;
    font-size: 22px;
  }
  .chooseColors h3,
  .chooseColors h4 {
    font-size: calc(var(--index) * 1.5);
    line-height: calc(var(--index) * 2);
  }
  .h3_not_found {
    font-size: calc(var(--index) * 3);
  }
}
@media (max-width: 500px) {
  .step_number_two {
    right: 15px;
    bottom: 10px;
  }
  .container {
    min-width: 100vw;
  }
  .currentCaseElement {
    flex-direction: column;
  }
  .editSessionsButton {
    order: -1;
    padding: 10px 10px 0 0;
  }
  .card_image {
    order: -1;
    width: 100%;
    height: calc(var(--index) * 15);
    border-bottom-left-radius: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .foneImg {
    width: 80vw;
  }
  .exit_button {
    font-size: calc(var(--index) * 1);
  }
  .exit_button img {
    max-width: calc(var(--index) * 2);
  }
  .currentCase h2 {
    font-size: calc(var(--index) * 1.3);
  }
  .currentCase h6 {
    font-size: calc(var(--index) * 1);
  }
  .currentCase span {
    font-size: calc(var(--index) * 1);
  }
  .edit_image {
    max-width: calc(var(--index) * 3);
  }
}
