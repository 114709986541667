.question_input {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #808080;
}
.variants_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  text-align: center;
}
.add_variant {
  width: 100%;
  padding: calc(var(--index) * 0.1) calc(var(--index) * 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px dashed #808080;
  border-radius: 8px;
  color: #808080;
  cursor: pointer;
  font-size: calc(var(--index) * 0.1);
}
.add_variant img {
  max-width: calc(var(--index) * 0.7);
}
.checkIsCorrect {
  position: absolute;
  top: -30%;
  right: 0;
  border: 1px solid white;
  width: calc(var(--index));
  height: calc(var(--index));
  cursor: pointer;
  z-index: 5;
}

@media (max-width: 700px) {
  .add_variant {
    font-size: calc(var(--index) * 1);
  }
  .add_variant img {
    max-width: calc(var(--index) * 7);
  }
}
@media (max-width: 600px) {
  .variants_block {
    grid-template-columns: 1fr;
  }
}
