.star {
  cursor: pointer;
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.star span{
  position: absolute;
}

