.App {
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

:root {
  --index: 1vw + 1vh;
}
.adminBackground,
.appBackground {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  min-width: 100vw;
}

.mainContainer {
  padding: calc(var(--index));
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 1000px;
}
.containerQuestions {
  display: flex;
  justify-content: center;
  margin: auto 0 40px 0;
  transition: 0.3s;
}
.nextOrPrevConsole {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nextOrPrevConsole img {
  cursor: pointer;
  max-width: 50px;
}

.nextOrPrevConsole img:last-child {
  transform: rotate(180deg);
}
.nextOrPrevConsole span {
  cursor: pointer;
  font-size: 30px;
  transition: 0.5s;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  border: 1px solid #94c11c;
  border-radius: 50%;
}
.nextOrPrevConsole span:hover {
  color: white;
  border: 1px solid white;
}
.pagination {
  display: flex;
  gap: 5px;
}
.pagination div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
}
.poweredByMero {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #252525;
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px 30px;
  overflow: hidden;
  opacity: 0.8;
}
.finishButton {
  position: fixed;
  top: calc(var(--index) * 3);
  right: calc(var(--index) * 3);
  border: 3px solid black;
  width: calc(var(--index) * 20);
  height: calc(var(--index) * 20);
  border-radius: 50%;
  margin: 0 auto;
  cursor: pointer;
  font-size: calc(var(--index) * 1.7);
  font-weight: 700;
}
.finishButton:hover {
  background: white;
}
.main_welcome {
  min-width: 30vw;
}
.ask_lector {
  cursor: pointer;
  position: absolute;
  font-size: calc(var(--index) * 0.5);
  padding: calc(var(--index) * 0.5) calc(var(--index) * 2);
  background-color: black;
  border-radius: calc(var(--index) * 0.5);
  z-index: 3;
  top: 105%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.label {
  width: max-content;
  height: max-content;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: block;
  padding: 10px 0;
}
.label img {
  width: calc(var(--index) * 30);
}

.start_logo {
  animation: start 3s linear infinite;
}
@keyframes start {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1400px) {
  .appBackground {
    width: auto;
  }
  .label img {
    width: calc(var(--index) * 20);
  }
}
@media (max-width: 1100px) {
  .mainContainer {
    max-width: 95vw;
  }
  .label {
    bottom: 2px;
  }
}
@media (max-width: 500px) {
  .main_welcome {
    min-width: 95vw;
  }
  .ask_lector {
    font-size: calc(var(--index) * 2);
    padding: calc(var(--index)) calc(var(--index) * 3);
    border-radius: calc(var(--index));
  }
}
@media (max-width: 450px) {
  .poweredByMero {
    gap: 5px;
  }
  .mainContainer {
    padding: calc(var(--index) * 3) calc(var(--index) * 2);
  }
  .poweredByMero span {
    font-size: 14px;
    max-width: 100px;
  }
  .poweredByMero img {
    width: 100px;
  }
  .pagination {
    gap: 3px;
  }
  .pagination div {
    width: 7px;
    height: 7px;
  }
}
