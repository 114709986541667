.linkContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.csvLink {
  text-decoration: none;
  background-color: green;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: white;
}
.pdfLink {
  text-decoration: none;
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
}
.clearData {
  text-decoration: none;
  background-color: #272a55;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
}
