.main_ask {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: calc(var(--index));
}
.main_personal_data {
  padding: calc(var(--index) * 3) calc(var(--index) * 3);
  border-radius: 20px;
}
.for_alert_modal {
  position: absolute;
  top: 0;
  background-color: rgba(64, 64, 64, 0.85);
  left: 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_personal_data h2 {
  text-align: center;
  font-size: calc(var(--index) * 3);
  letter-spacing: 2px;
  font-weight: 900;
  margin: 0 auto calc(var(--index) * 2);
  font-family: "GrandisExtended-light";
}
.personal_inputs_and_button {
  display: flex;
  gap: 3vh;
  flex-direction: column;
  align-items: center;
}
.personal_inputs_and_button input {
  background-color: black;
  width: 80%;
  font-size: calc(var(--index) * 1.5);
  padding: calc(var(--index) * 0.5) calc(var(--index));
  border-radius: calc(var(--index) * 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.personal_inputs_and_button input::placeholder {
  color: #808080;
  font-weight: 900;
  font-size: calc(var(--index));
}

.set_personal_button {
  border: none;
  padding: calc(var(--index) * 0.5) calc(var(--index) * 3);
  width: 50%;
  border-radius: calc(var(--index) * 0.2);
  font-size: calc(var(--index));
}
.questions_pool {
  width: 90vw;
  background-color: rgb(235, 245, 245);
  border-radius: 20px;
  padding: calc(var(--index));
}
.questions_pool h2 {
  font-family: "GrandisExtended-light";
  font-size: calc(var(--index) * 3);
  letter-spacing: 2px;
  font-weight: 900;
  margin: 0 auto calc(var(--index) * 2);
}

.scroll_questions_pool {
  padding-right: 10px;
}
.scroll_questions_pool::-webkit-scrollbar {
  width: 5px;
}
.scroll_questions_pool::-webkit-scrollbar-track {
  background-color: #808080;
  border-radius: 10em;
}
.scroll_questions_pool::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 10em;
}

.ask_input_and_send_button {
  display: flex;
  gap: calc(var(--index));
  position: relative;
  max-height: 100px;
}
.ask_input_and_send_button textarea {
  width: 80%;
  padding: 5px calc(var(--index));
  font-size: calc(var(--index) * 2);
  border-radius: 10px;
  border: none;
  overflow-y: auto;
  resize: none;
}
.ask_input_and_send_button textarea::-webkit-input-placeholder {
  width: 80%;
  padding: 5px calc(var(--index));
  font-size: calc(var(--index) * 2);
  border-radius: 10px;
}

.preloader {
  width: max-content;
  height: max-content;
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;
  left: 2%;
  display: flex;
  gap: 1vw;
  align-items: center;
}

.preloader div {
  width: calc(var(--index) * 4);
  height: calc(var(--index) * 4);
  margin: auto 0;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid white;
  animation: rotate 1s linear infinite;
}
.preloader span {
  font-size: calc(var(--index) * 0.1);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ask_input_and_send_button button {
  border: none;
  width: 20%;
  padding: calc(var(--index) * 0.5) calc(var(--index));
  border-radius: 10px;
  color: white;
  font-size: calc(var(--index) * 0.8);
  cursor: pointer;
}
.question_element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding: 20px;
  border-radius: 10px;
  gap: 10px;
}
.question {
  font-size: calc(var(--index));
}
.point {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: white;
}
.range_botton {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
  border-radius: 10px;
  cursor: pointer;
}
.range_botton span {
  font-size: calc(var(--index) * 0.1);
}
.range_botton img {
  width: calc(var(--index) * 2);
  grid-area: a;
}
.star_check {
  animation: 0.5s check linear;
}

.range_botton span:nth-child(2) {
  grid-area: b;
}

.range_botton span:nth-child(4) {
  grid-area: c;
}

@keyframes check {
  0% {
    transform: scale(1) rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
.private_police {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.private_police a {
  text-decoration: none;
  font-size: calc(var(--index) * 0.5);
}
.private_police span {
  font-size: calc(var(--index) * 0.5);
}
.private_police div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  width: max-content;
}
.private_police div:nth-child(1) div {
  overflow: hidden;
  height: 100%;
  width: 7%;
}
.private_police div:nth-child(1) div span {
  font-size: calc(var(--index) * 1.5);
  color: red;
  transition: 0.5s;
}

@media (max-width: 700px) {
  .range_botton span {
    font-size: calc(var(--index) * 0.7);
  }
  .questions_pool {
    height: 80vh;
    width: 95vw;
  }
  .question_element {
    padding: 20px 5px;
  }
  .range_botton {
    gap: 5px;
    padding: 2px 5px;
  }
  .questions_pool h2 {
    font-size: calc(var(--index) * 2);
    margin: 0 auto calc(var(--index));
  }
  .preloader {
    gap: 3vw;
  }
  .preloader span {
    font-size: calc(var(--index));
  }
}
@media (max-width: 600px) {
  .private_police {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .private_police div:nth-child(1) div {
    width: auto;
  }
  .private_police div:nth-child(1) {
    gap: 10px;
  }
  .private_police a {
    font-size: calc(var(--index));
  }
  .private_police span {
    font-size: calc(var(--index));
  }
}

@media (max-width: 480px) {
  .range_botton {
    display: grid;
    grid-template-areas:
      "a c"
      "b b";
    padding: 7px 17px;
  }
  .questions_pool {
    width: 98vw;
  }
  .private_police {
    justify-content: space-between;
    align-items: center;
  }
  .question {
    font-size: calc(var(--index) * 1.5);
  }
  .range_botton span {
    font-size: calc(var(--index) * 0.7);
  }
  .range_botton span:nth-child(3) {
    display: none;
  }
  .ask_input_and_send_button input {
    padding: 15px calc(var(--index) * 1.2);
    font-size: calc(var(--index) * 1.5);
  }
  .ask_input_and_send_button button {
    padding: calc(var(--index) * 0.6) calc(var(--index));
    font-size: calc(var(--index));
  }
  .main_personal_data h2 {
    font-size: calc(var(--index) * 2);
    letter-spacing: 1px;
  }
  .personal_inputs_and_button input {
    width: 100%;
    font-size: calc(var(--index) * 2);
    border-radius: calc(var(--index) * 0.6);
  }
  .personal_inputs_and_button input::placeholder {
    font-size: calc(var(--index) * 1.5);
  }
}
