.main_block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  animation: 0.3s opacity linear forwards;
  animation-delay: 0.3s;
  opacity: 0;
}
.main_block h2 {
  font-size: calc(var(--index) * 1.5);
}
.go_to_new_session {
  font-size: calc(var(--index) * 1.5);
  padding: 11px 24px;
  border: 1px dashed #808080;
  border-radius: 10em;
  cursor: pointer;
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.main_block input {
  color: #fff;
  font-size: calc(var(--index) * 4);
  text-align: center;
}
.edit_buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}
.edit_buttons button {
  border: 1px solid white;
  padding: calc(var(--index) * 0.1) calc(var(--index) * 1);
  border-radius: 10em;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: calc(var(--index) * 0.7);
  cursor: pointer;
}
.edit_buttons button:nth-child(1) {
  background-color: transparent;
}
.edit_buttons button:nth-child(2) {
  color: black;
}

.buttons_yes_now_container {
  margin: 2vh 0 0 0;
  display: flex;
  gap: 3vw;
}
.h2_delete_session {
  text-align: center;
  font-size: calc(var(--index) * 1.9);
  font-weight: 900;
}
.button_now,
.button_yes {
  padding: 11px 25px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10em;
  font-size: calc(var(--index) * 1.5);
  cursor: pointer;
}
.button_now {
  border: 1px dashed white;
}
.information {
  max-width: 80vw;
  text-align: center;
  font-size: calc(var(--index) * 0.8);
  line-height: calc(var(--index) * 2);
  font-weight: 100;
}
.active_or_close_buttons {
  display: flex;
  align-items: center;
  gap: 2vw;
}
.check_input {
  position: relative;
  width: calc(var(--index));
  height: calc(var(--index));
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}
.check_input span {
  color: black;
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: calc(var(--index) * 5);
  transition: 0.5s;
  font-size: "GrandisExtended-light";
  font-weight: 900;
}
.email_for_change_pass_div input {
  padding: 11px;
  font-size: 20px;
  transition: 1s;
}
.email_for_change_pass_div input::placeholder {
  font-weight: 700;
}
.email_for_change_pass_div h2 {
  font-size: calc(var(--index));
  margin-bottom: calc(var(--index) * 4);
  max-width: 500px;
  font-weight: 900;
}
.buttons_div_for_change_pass {
  display: flex;
  gap: 1vw;
  padding: calc(var(--index));
}
.buttons_div_for_change_pass button {
  color: black;
  width: max-content;
  padding: 11px 24px;
  background-color: white;
  border: none;
  border-radius: 10em;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;
}
.buttons_div_for_change_pass button:nth-child(1) {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
.loading {
  width: 25px;
  height: 25px;
  border: 3px solid #dbdbdb;
  border-top: 3px solid #363535;
  border-radius: 50%;
  margin: 0 auto;
  animation: 1s loading linear infinite;
}
@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1000px) {
  .email_for_change_pass_div h2 {
    font-size: calc(var(--index)*2);
    max-width: 350px;
  }
}
@media (max-width: 800px) {
  .check_input {
    width: calc(var(--index) * 2);
    height: calc(var(--index) * 2);
  }
}
@media (max-width: 500px) {
  .email_for_change_pass_div h2 {
    font-size: calc(var(--index)*3);
  }
}