.modaration_screen,
.approve_container {
  height: 100vh;
  background-color: black;
  width: 0px;
  position: absolute;
  right: 0;
  z-index: 3;
  transition: 0.5s;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
}
.approve_container {
  left: 0;
  direction: rtl;
}

.container {
  margin: calc(var(--index));
  animation: 0.5s open linear forwards;
  animation-delay: 0.3s;
  display: flex;
  flex-direction: column;
  gap: calc(var(--index) * 0.1);
  transform: translateX(40vw);
}
@keyframes open {
  from {
    opacity: 0;
    transform: translateX(40vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.question_case {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-bottom: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}
.questionBody {
  color: white;
  font-size: calc(var(--index) * 1);
}

.approve,
.reject {
  font-size: calc(var(--index) * 1);
  display: flex;
  gap: 3px;
}

.approve {
  cursor: pointer;
  color: green;
  font-weight: 900;
}
.approve span {
  color: white;
}
.reject {
  cursor: pointer;
  color: red;
  margin-top: 10px;
}
.reject span {
  color: white;
}
.approve_questions_container {
  margin: 1vw;
}

.current_approve_question {
  color: white;
  direction: ltr;
  display: flex;
  padding: 20px 10px;
  border-radius: 10px;
  align-items: center;
  gap: 1vw;
  width: 100%;
  /* overflow: hidden; */
}
.set_isanswered {
  text-align: center;
  width: 0;
  overflow: hidden;
  padding: 5px 0;
  transition: 0.5s;
  cursor: pointer;
  background-color: green;
  border-radius: 10px;
}

.current_approve_question div {
  font-size: calc(var(--index));
}
.current_approve_question div:nth-child(2) {
  color: white;
  transition: 0.5s;
  min-width: 90%;
}
.current_approve_question div:nth-child(3) {
  color: red;
  cursor: pointer;
  margin-left: auto;
  transition: .5s;
  opacity: .5;
}
