.main_form_block {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  padding: calc(var(--index));
  gap: 2vh;
  margin: 4vh auto;
}
.main_form_block div {
  font-size: calc(var(--index));
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.5s;
  overflow: hidden;
}
.main_form_block div span {
  font-size: calc(var(--index) * 2);
  transition: 0.5s;
}

.back_button {
  display: block;
  padding: 10px 15px;
  border-radius: 50%;
  border: 1px solid white;
  width: max-content;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 20px;
}
.title {
  max-width: 900px;
  font-size: calc(var(--index) * 2);
}
@media (max-width: 450px) {
  .main_form_block div {
    font-size: calc(var(--index) * 2.5);
  }
  .main_form_block div span {
    font-size: calc(var(--index) * 3);
  }
}
