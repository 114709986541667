.current_session_container {
  margin: 0 0 auto 0;
  max-width: 100vw;
  position: relative;
  overflow-y: auto;
  padding-right: 10px;
}
.current_session_container::-webkit-scrollbar {
  width: 5px;
}
.current_session_container::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.current_session_container::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}
.deleteModalCss {
  background-color: black;
  padding: 20px 30px;
  border-radius: 30px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;
}
.deleteModalCss div {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5vw;
}
.deleteModalCss div span {
  cursor: pointer;
}
.deleteModalCss div span:nth-child(1) {
  background-color: green;
  padding: 10px 20px;
  border-radius: 10em;
}
.deleteModalCss div span:nth-child(2) {
  background-color: red;
  padding: 10px 20px;
  border-radius: 10em;
}

:root {
  --circle: calc(var(--index) * 3);
}
.back_and_delete_session {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back_and_delete_session div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: calc(var(--index) * 1);
  cursor: pointer;
}
.back_and_delete_session div:nth-child(1) span:nth-child(1) {
  width: var(--circle);
  height: var(--circle);
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: calc(var(--index) * 2);
}
.back_and_delete_session div:nth-child(1) span:nth-child(2) {
  font-size: calc(var(--index) * 1);
}
.back_and_delete_session div:nth-child(2) {
  display: flex;
  width: var(--circle);
  height: var(--circle);
  border: 1px solid white;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  gap: calc(var(--index) * 1);
  cursor: pointer;
  font-weight: 100;
  padding-bottom: 0.2vh;
  font-size: calc(var(--index) * 0.6);
}
.title_container {
  margin: calc(var(--index) * 0.5) auto auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.session_title {
  font-size: calc(var(--index) * 3);
  letter-spacing: 1px;
  font-weight: bolder;
  display: flex;
  align-items: center;
  gap: calc(var(--index) * 0.7);
}
.quiz_form_container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1vw;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px 30px;
  border-radius: 10px;
  margin: 10px 0 0 0;
}
.for_quiz_bet {
  width: 40px;
  height: 20px;
  background-color: white;
  border-radius: 10em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  margin-left: 5vw;
}
.for_quiz_bet div {
  width: 15px;
  height: 15px;
  background-color: black;
  border-radius: 50%;
  transition: 0.5s;
}
.bet_quiz_h3 {
  font-size: calc(var(--index));
  position: relative;
}
.bet_quiz_h3 span {
  position: absolute;
  bottom: 110%;
  border-radius: 50%;
  border: 1px solid #808080;
  width: calc(var(--index) * 1.2);
  height: calc(var(--index) * 1.2);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 95%;
}
.quiz_form_container h2 {
  font-size: calc(var(--index) * 1.5);
  margin: 10px 0;
  font-weight: bolder;
}
.quiz_form_container span {
  font-size: calc(var(--index));
  margin-bottom: -5px;
  cursor: pointer;
}
.form_body {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.89);
  top: 100%;
  left: 0;
  transition: 0.7s;
  z-index: 5;
  display: flex;
  flex-direction: column;
  padding: calc(var(--index));
  border-radius: 10px;
  overflow: hidden;
}
.form_value {
  font-size: calc(var(--index));
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  cursor: pointer;
  overflow: hidden;
  transition: 0.7s;
  font-style: italic;
}
.form_value span {
  transition: 0.7s;
}
.session_title img {
  cursor: pointer;
  max-width: calc(var(--index) * 5);
}
.active_session_owner_and_id {
  margin-top: calc(var(--index) * 0.5);
  display: flex;
  align-items: center;
  gap: calc(var(--index) * 0.7);
}
.active_session_owner_and_id div:nth-child(1) {
  width: 70px;
  height: 40px;
  border-radius: 10em;
  background-color: white;
  padding: 7px;
  transition: 0.3s;
  cursor: pointer;
}
.active_session_owner_and_id div:nth-child(1) div:nth-child(1) {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: black;
  transition: 0.3s;
  margin-left: 30px;
}
.active_session_owner_and_id div:last-child {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: calc(var(--index) * 0.5);
}
.active_session_owner_and_id div:last-child img {
  cursor: pointer;
}
.active_session_owner_and_id div:nth-child(2) {
  font-size: calc(var(--index) * 1.5);
}
.active_session_owner_and_id div:nth-child(3) {
  color: #808080;
  font-size: calc(var(--index) * 0.5);
}

.links {
  display: flex;
  margin-top: calc(var(--index) * 1);
  align-items: center;
  gap: calc(var(--index) * 0.7);
}
.links div {
  height: 180px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  width: calc(var(--index) * 40);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: calc(var(--index) * 0.1);
}
.links div span {
  text-align: center;
  font-size: calc(var(--index) * 0.1);
}
.links div img {
  cursor: pointer;
  max-width: calc(var(--index) * 5);
}
.notes {
  position: relative;
  margin-top: calc(var(--index) * 1);
  height: 160px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
}
.notes textarea {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #808080;
  width: 100%;
}
.notes button {
  position: absolute;
  top: 5%;
  right: 5%;
  padding: 10px 25px;
  color: black;
  background: rgb(76, 250, 76);
  border-radius: 10em;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
.questions_and_styles {
  display: flex;
  gap: 3vw;
  justify-content: space-between;
}
.style_case h1 {
  margin: 20px 0;
  font-size: calc(var(--index) * 1.5);
  font-weight: 100;
}
.save_colors_or_image {
  padding: 10px 25px;
  color: black;
  /* background: rgb(76, 250, 76); */
  background: white;
  border-radius: 10em;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.clouse_edit_mode {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .active_session_owner_and_id {
    flex-direction: column;
    align-items: flex-start;
  }
  .session_title {
    font-size: calc(var(--index) * 2);
  }
  .links {
    flex-direction: column;
    align-items: flex-start;
  }
  .links div {
    height: max-content;
    width: 100%;
    align-items: flex-start;
    padding: calc(var(--index) * 1);
  }
  .links div span {
    text-align: center;
    font-size: calc(var(--index) * 1);
  }
  .links div img {
    max-width: calc(var(--index) * 5);
  }
  .questions_and_styles {
    flex-direction: column;
  }
  .quiz_form_container {
    padding: calc(var(--index) * 1);
    width: 100%;
  }
  .for_quiz_bet {
    margin-left: auto;
  }
}
