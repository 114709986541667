.head_question_container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 3px;
}
.head_question_container::-webkit-scrollbar {
  width: 5px;
}
.head_question_container::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.head_question_container::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}

.head {
  margin: 0 auto 10px auto;
  text-align: center;
  font-size: calc(var(--index) * 2.5);
  font-weight: 900;
}
.head_h3 {
  font-size: calc(var(--index) * 2);
  margin-bottom: 10px;
}
.main {
  display: flex;
  flex-direction: column;
  gap: calc(var(--index) * 1.1);
}


.mainChildren {
  position: relative;
  padding: 25px 40px 25px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: calc(var(--index) * 1.5);
  transition: 0.5s;
}
.mainChildrenText {
  border-bottom: 1px solid #00457c;
  padding: 0;
  padding-bottom: 10px;
  padding-left: 20px;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
}

.clouse {
  position: absolute;
  display: inline-block;
  height: max-content;
  top: 0;
  bottom: 0;
  right: calc(var(--index) * 1.05);
  font-size: calc(var(--index) * 1.6);
  font-weight: 400;
  margin: auto 0;
  transition: 0.5s;
}
.clouse:hover {
  transform: scale(1.1, 1.1);
  text-shadow: 0 0 5px white;
  color: black;
  font-weight: bold;
  transition: 0.5s;
}
@media (max-width: 850px) {
}
@media (max-width: 520px) {
  .head_h3 {
    margin: 0 auto 20px auto;
  }
}
@media (max-width: 500px) {
  .head_question_container{
    width: 95vw;
  }
}