.warning {
  padding: 12svh 8vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid black;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.warning h2 {
  max-width: 300px;
}
.warningButtons {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}
.warningButtons button {
  padding: 10px 30px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.case_panel_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border: 1px solid black;
  padding: 55px 10px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.85);
  position: relative;
  min-width: 350px;
}
.block_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow: auto;
  padding: 5px 15px;
}
.block_container::-webkit-scrollbar {
  width: 5px;
}
.block_container::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.block_container::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}
.notification {
  background-color: white;
  color: black;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: calc(var(--index) * 2);
  height: calc(var(--index) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 1s puls linear infinite;
  cursor: pointer;
}
.changeModerator {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.moderator,
.screen {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 65%;
  background-color: #808080;
  padding: 10px 15px;
  border-radius: 10em;
  cursor: pointer;
  transition: 1s;
}

.human,
.robot {
  transition: 1s;
  z-index: 2;
  font-size: 14px;
}
.currentModerator {
  background-color: white;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 10em;
  position: absolute;
  transition: 1s;
}
@keyframes puls {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.approve_questions {
  margin: 0 auto auto 0;
  cursor: pointer;
  position: absolute;
  left: 3px;
  padding: 10px;
  border-radius: 10px;
  background-color: black;
  border: 1px solid white;
  bottom: 3px;
}
@media (max-width: 650px) {
  .notification {
    width: calc(var(--index) * 3.5);
    height: calc(var(--index) * 3.5);
  }
}
