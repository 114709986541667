.question_input {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #808080;
}

.stars {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
}
.scale {
  /* min-width: 602px; */
  width: 85%;
  max-width: 550px;
  height: 2px;
  background-color: azure;
  position: relative;
  display: flex;
  align-items: center;
}
.block {
  position: absolute;
  width: 6px;
  height: 15px;
  background-color: azure;
  left: 0;
  border-radius: 3px;
  transition: 0.5s;
}
.dragDiv {
  position: absolute;
  left: 0;
  width: 20px;
  height: 40px;
  border: 5px solid azure;
  border-radius: 10em;
  z-index: 5;
  cursor: grab;
  max-width: 100%;
}
