.compiled_sessions_container {
  width: 100%;
  margin: 0 0 auto 0;
  overflow-y: auto;
  padding-right: 10px;
  /* background-color: aqua; */
}
.compiled_sessions_container::-webkit-scrollbar {
  width: 5px;
}
.compiled_sessions_container::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.compiled_sessions_container::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}
.session_line_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.session_line_name h1 {
  display: flex;
  align-items: center;
  gap: 20px;
}
.steps {
  margin: 20px 0;
  font-size: calc(var(--index) * 1.5);
}
.session_line_name img {
  cursor: pointer;
  max-width: calc(var(--index) * 5);
}
.session_title {
  font-family: "GrandisExtended-light";
  font-size: calc(var(--index) * 3);
  letter-spacing: 1px;
  font-weight: bolder;
  display: flex;
  align-items: center;
}
.all_blocks {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.block_container {
  background-color: rgba(0, 0, 0, 0.7);
  padding: calc(var(--index));
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  /* overflow: hidden; */
  transition: 0.7s;
}
.clouse_edit_mode {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  max-width: calc(var(--index) * 4);
}

.add_block {
  padding: calc(var(--index) * 0.1) calc(var(--index) * 1);
  margin: 15px auto 0;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid white;
  border-radius: 10em;
  cursor: pointer;
  font-size: calc(var(--index) * 0.1);
}
.add_block img {
  transition: .3s;
  max-width: calc(var(--index) * 0.7);
}
.add_block img:hover {
  transform: scale(1.2);
}
.header_block_name_line {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.header_block_name_line div {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.header_block_name_line div img:last-child:hover {
  cursor: grab;
}
.header_block_name_line span {
  font-size: calc(var(--index) * 1.8);
  cursor: pointer;
}
.header_block_name_line img {
  cursor: pointer;
  max-width: calc(var(--index) * 5);
}
.header_block_name_line h2 {
  font-size: calc(var(--index) * 1.8);
}
.questions_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: 0.5s;
}
.question_in_block {
  background-color: #4040408a;
  border-radius: 16px;
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.container_of_types_questions {
  /* transition: 0.5s; */
  border-radius: 0 0 10px 0;
  position: absolute;
  top: 0;
  width: 90%;
  left: 0;
  background: black;
  z-index: 10;
  overflow: hidden;
}

.container_of_types_questions ul li {
  list-style-type: none;
  padding: 10px 20px;
  font-size: var(--index);
  cursor: pointer;
  border-radius: 10px;
  transition: 0.5s;
}

.open_types_button {
  width: max-content;
  margin: 5px 20px 0 auto;
  cursor: pointer;
  position: absolute;
  /* top: 0; */
  right: -15px;
  font-size: 18px;
  transition: 0.5s;
}
.sandwich {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: grab;
  padding: 25px 25px 25px 0;
  z-index: 3;
}

.question_content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.question_content input {
  font-size: calc(var(--index) * 0.7);
  padding-right: 35px;
}
.question_content input::placeholder {
  font-weight: 700;
  font-size: calc(var(--index) * 0.7);
}
.add_question_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4040408a;
  width: 100%;
  padding: calc(var(--index));
  border-radius: 16px;
  gap: 10px;
  font-size: calc(var(--index) * 0.2);
  cursor: pointer;
}
.add_question_button img {
  max-width: calc(var(--index) * 0.7);
}
.delete_question {
  top: 8px;
  right: 8px;
  position: absolute;
  cursor: pointer;
}
.question_input {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #808080;
}
.variants_block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  text-align: center;
}
.variant_input {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #808080;
}
.trash_variant {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
.add_variant {
  width: 100%;
  padding: calc(var(--index) * 0.1) calc(var(--index) * 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px dashed #808080;
  border-radius: 8px;
  color: #808080;
  cursor: pointer;
  font-size: calc(var(--index) * 0.1);
}
.add_variant img {
  max-width: calc(var(--index) * 0.7);
}
.onlyAsk {
  display: flex;
  gap: calc(var(--index) * 0.1);
  align-items: center;
  margin-bottom: calc(var(--index) * 0.1);
}
.onlyAsk p {
  font-size: calc(var(--index) * 0.5);
  font-weight: 100;
}
.switch {
  width: calc(var(--index) * 2.5);
  height: calc(var(--index) * 0.45);
  background-color: white;
  border-radius: 10em;
  padding: 0 2px 0 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s;
}
.switch div {
  background-color: black;
  width: calc(var(--index) * 0.01);
  height: calc(var(--index) * 0.01);
  border-radius: 50%;
}
@media (max-width: 900px) {
  .onlyAsk {
    gap: calc(var(--index) * 1.5);
  }
  .onlyAsk p {
    font-size: calc(var(--index) * 1);
  }
  .switch {
    width: 40px;
    height: 20px;
  }
  .switch div {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 700px) {
  .add_block {
    font-size: calc(var(--index) * 1);
  }
  .add_block img {
    max-width: calc(var(--index) * 7);
  }
  .add_question_button {
    font-size: calc(var(--index) * 1);
  }
  .add_question_button img {
    max-width: calc(var(--index) * 7);
  }
  .question_content input {
    font-size: calc(var(--index) * 1);
  }
  .question_content input::placeholder {
    font-size: calc(var(--index) * 1);
  }
  .add_variant {
    font-size: calc(var(--index) * 1);
  }
  .add_variant img {
    max-width: calc(var(--index) * 7);
  }
}
@media (max-width: 600px) {
  .variants_block {
    grid-template-columns: 1fr;
  }
  .question_content {
    gap: 10px;
  }
  .variant_input {
    padding: 5px;
  }
  .header_block_name_line div {
    gap: 15px;
    margin-bottom: 10px;
  }
}
