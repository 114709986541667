@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@1,200&family=PT+Sans+Narrow&family=Play&family=Rubik+Glitch&display=swap");
@font-face {
  font-family: "GrandisThin";
  src: url("./fonts/GrandisThin.ttf") format("truetype"); /* использую этот*/
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "GrandisExtended";
  src: url("./fonts/GrandisExtended-Black.ttf") format("OpenType");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "GrandisExtended-light";
  src: url("./fonts/GrandisExtended-Light.ttf") format("OpenType"); /* использую этот*/
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "GrandisExtended-italic";
  src: url("./fonts/GrandisExtended-BlackItalic.ttf") format("OpenType");
  font-style: normal;
  font-weight: 100;
}
body {
  margin: 0;
  background-color: black;
  font-family: "PT Sans Narrow", sans-serif, "Play", sans-serif, "Nunito Sans",
    sans-serif, "Rubik Glitch", cursive;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
body::-webkit-scrollbar {
  width: 0;
}

code {
  font-family: "PT Sans Narrow", sans-serif, "Play", sans-serif, "Nunito Sans",
    sans-serif, "Rubik Glitch", cursive;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  font-family: "GrandisThin";
  font-weight: 900;
}
input {
  border: none;
  background-color: transparent;
  font-size: 16px;
}
input:active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
h2 {
  font-weight: 100;
}

.fff {
  color: rgba(255, 255, 255, 0.514);
}
