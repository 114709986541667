.main_users {
  margin: 0 0 auto 0;
  width: 100%;
}
.comeback {
  margin-right: auto;
  font-size: calc(var(--index) * 4);
  cursor: pointer;
  width: max-content;
}

.users_title {
  text-align: center;
  font-size: calc(var(--index) * 2.5);
}
.users_container {
  margin: calc(var(--index) * 2.5) 0 0 0;
  display: flex;
  flex-direction: column;
  gap: calc(var(--index) * 0.1);
  overflow-y: auto;
  /* height: 60vh; */
  padding-right: 20px;
}
.users_container::-webkit-scrollbar {
  width: 5px;
}
.users_container::-webkit-scrollbar-track {
  background-color: #808080;
  border-radius: 10em;
}
.users_container::-webkit-scrollbar-thumb {
  background-color: #404040;
  border-radius: 10em;
}
.user {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  padding: calc(var(--index) * 0.01) 0;
  border-bottom: 1px solid #808080;
}
.one_user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.one_user h2 {
  font-size: calc(var(--index) * 1.5);
}
.one_user span:nth-child(2) {
  font-size: calc(var(--index) * 0.5);
  color: #808080;
}
.one_user div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 2vw;
}
.one_user div:nth-child(3) span {
  font-size: calc(var(--index) * 1);
}
.one_user div:nth-child(3) img {
  max-width: calc(var(--index) * 3);
  cursor: pointer;
}
.tariffs {
  margin-top: 1vh;
  display: flex;
  gap: 3vw;
  align-items: center;
}
.tariffs span:nth-child(1) {
  font-weight: 700;
  font-size: calc(var(--index) * 0.5);
}
.tariffs span {
  font-weight: 100;
  font-size: calc(var(--index) * 0.1);
  padding: 8px 25px;
  border-radius: 10em;
  cursor: pointer;
}
.counter_block {
  display: flex;
  align-items: center;
}
.counter_block span {
  cursor: pointer;
}
.add_sessions_button {
  margin: 10px 0 0 0;
  padding: 5px 20px;
  border: none;
  color: black;
  border-radius: 10em;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 5px;
}
.add_sessions_button img {
  max-width: 20px;
}
.search_users {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 50px 0 10px 0;
}
.search_users input {
  border-bottom: 1px solid #808080;
  font-size: calc(var(--index));
  width: 70%;
  padding: 10px 20px;
}
.search_users button {
  font-size: calc(var(--index));
  color: black;
  background-color: white;
  border: none;
  border-radius: 10em;
  padding: 10px 20px;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
