.main {
  padding: calc(var(--index) * 5);
  border-radius: 10px;
  min-height: 50vh;
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
}

.main h2 {
  text-align: center;
  font-size: calc(var(--index) * 3);
  letter-spacing: 2px;
  font-weight: 900;
  margin: 0 auto calc(var(--index) * 2);
  font-family: "GrandisExtended-light";
}
.main p {
  text-align: center;
  font-size: calc(var(--index) * 2);
  font-weight: 700;
}
.info_after_ask {
  max-width: 70%;
  display: block;
  font-size: calc(var(--index) * 2);
  /* text-align: center; */
  margin: calc(var(--index) * 2) auto 0;
}
.main button {
  border: none;
  width: calc(var(--index) * 15);
  height: calc(var(--index) * 15);
  font-size: calc(var(--index) * 1.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10vh auto;
  cursor: pointer;
}
@media (max-width: 1100px) {
  .main {
    max-width: 95vw;
  }
}
@media (max-width: 700px) {
  .main {
    padding: calc(var(--index));
  }
  .main p {
    max-width: 300px;
  }
  .main h2 {
    font-size: calc(var(--index) * 1.8);
  }
  .info_after_ask {
    margin: 5px auto 0;
  }
}
@media (max-width: 500px) {
  .main {
   width: 95vw;
  }

}
