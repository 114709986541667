.head_question_container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 3px;
}
.head_question_container::-webkit-scrollbar {
  width: 5px;
}
.head_question_container::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.head_question_container::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}
.head {
  margin: 0 auto 10px auto;
  text-align: center;
  font-size: calc(var(--index) * 2.5);
  font-weight: 900;
}
.head_h3 {
  font-size: calc(var(--index) * 2);
  margin-bottom: 10px;
}
.mainChildren {
  position: relative;
  padding: 25px 40px 25px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: calc(var(--index) * 1.5);
  transition: 0.5s;
}
.quiz_form {
  overflow-y: auto;
  padding-right: 3px;
}
.quiz_form::-webkit-scrollbar {
  width: 5px;
}
.quiz_form::-webkit-scrollbar-thumb {
  /* плашка-бегунок */
  background: white;
  border-radius: 10px;
}
.quiz_form::-webkit-scrollbar-track {
  /* фон */
  background: grey;
}
.form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  margin: 5vh 0 0 0;
}

.form_container input {
  background-color: black;
  width: 80%;
  font-size: calc(var(--index) * 1.5);
  padding: calc(var(--index) * 0.5) calc(var(--index));
  border-radius: calc(var(--index) * 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.form_container input::placeholder {
  color: #808080;
  font-weight: 900;
  font-size: calc(var(--index));
}
.free_text_area {
  background: transparent;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: calc(var(--index));
  margin-top: 10%;
  height: max-content;
}
.head_question_container_t{
  min-width: 50vw;
 }
  
.free_text_area::-webkit-scrollbar {
  width: 0;
}
.invocation {
  text-align: center;
  font-size: calc(var(--index) * 3);
  letter-spacing: 2px;
  font-weight: 900;
  margin: 0 auto calc(var(--index) * 2);
  font-family: "GrandisExtended-light";
}
.save_button {
  border: none;
  padding: calc(var(--index) * 0.5) calc(var(--index) * 3);
  width: 50%;
  border-radius: calc(var(--index) * 0.2);
  font-size: calc(var(--index));
  margin: 10px auto;
}
.block_variants {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  margin: 5vh 0 0 0;
}

.block_variants div {
  /* border: 1px solid white; */
  border-radius: 10px;
  width: 100%;
  padding: 10px 20px;
}
.clouse {
  position: absolute;
  display: inline-block;
  height: max-content;
  top: 0;
  bottom: 0;
  right: calc(var(--index) * 1.05);
  font-size: calc(var(--index) * 1.6);
  font-weight: 400;
  margin: auto 0;
  transition: 0.5s;
}
.bet_button {
  background-color: aliceblue;
  height: 25px;
  width: 50px;
  border-radius: 10em;
  padding: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.bet_button section {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  transition: 0.5s;
}
.bet_description {
  font-size: calc(var(--index) * 0.7);
}
.private_police {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}
.private_police a {
  text-decoration: none;
  font-size: calc(var(--index) * 0.5);
}
.private_police span {
  font-size: calc(var(--index) * 0.5);
}
.private_police div:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.private_police div:nth-child(1) div {
  overflow: hidden;
}
.private_police div:nth-child(1) div span {
  font-size: calc(var(--index) * 2);
  color: red;
  transition: 0.5s;
}

@media (max-width: 850px) {
  .free_text_area {
    /* margin-top: 30%; */
    font-size: calc(var(--index) * 2);
  }
}
@media (max-width: 600px) {
  .bet_description {
    font-size: calc(var(--index) * 1.5);
  }
  .private_police {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .private_police a {
    font-size: calc(var(--index));
  }
  .private_police span {
    font-size: calc(var(--index));
  }
}
@media (max-width: 520px) {
  .head_h3 {
    margin: 0 auto 20px auto;
  }
  .invocation {
    font-size: calc(var(--index) * 2);
    letter-spacing: 1px;
  }
  .private_police {
    width: 100%;
  }
  .form_container input {
    width: 100%;
    font-size: calc(var(--index) * 2);
    border-radius: calc(var(--index) * 0.6);
  }
  .form_container input::placeholder {
    font-size: calc(var(--index) * 1.5);
  }
}
@media (max-width: 500px) {
  .head_question_container{
    width: 95vw;
  }
  .head_question_container_t{
    width: 95vw;
  }
}