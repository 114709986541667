.head {
  width: max-content;
  margin: 0 auto 40px auto;
  max-width: 500px;
  text-align: center;
  font-size: 18px;
}

.main textarea {
  width: 100%;
  background: transparent;
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #00457c;
}
@media (max-width: 600px) {
  .head {
    max-width: 250px;
  }
}
